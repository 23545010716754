// src/components/WelcomeSection.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./WelcomeSection.css";

const WelcomeSection = () => {
  const { t } = useTranslation();

  return (
    <section className="welcome-section">
      <div className="welcome-overlay"></div>
      <div className="welcome-content text-center text-md-start">
        <h1 className="display-4">{t("welcome.title")}</h1>
        <p className="lead">{t("welcome.description")}</p>
        <p>&nbsp;</p>
        <p className="lead">{t("welcome.description1")}</p>
        <a
          href="https://app.otiliaai.com/#/register"
          className="btn btn-primary btn-lg"
        >
          {t("welcome.registerButton")}
        </a>
      </div>
    </section>
  );
};

export default WelcomeSection;
