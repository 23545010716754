// src/components/BenefitsOfUsingOtilia.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./BenefitsOfUsingOtilia.css";

import image from "../assets/img/ed86c5a34e5a3833bdba698ebc92083f.png";

const BenefitsOfUsingOtilia = () => {
  const { t } = useTranslation();

  return (
    <section id="benefits" className="benefits-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h3 className="display-6">{t("benefits.title")}</h3>
            <p>{t("benefits.description")}</p>
            <p>&nbsp;</p>
            <p>{t("benefits.description1")}</p>
            <p>
              <strong>{t("benefits.option_1_title")}</strong>{" "}
              {t("benefits.option_1")}
            </p>
            <p>
              <strong>{t("benefits.option_2_title")}</strong>{" "}
              {t("benefits.option_2")}
            </p>
            <p>
              <strong>{t("benefits.option_3_title")}</strong>{" "}
              {t("benefits.option_3")}
            </p>
          </div>
          <div className="col-md-6">
            <img
              src={image}
              alt="Mother and Baby"
              className="img-fluid rounded"
              height={400}
              width={600}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsOfUsingOtilia;
