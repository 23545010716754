import React from "react";
import { useTranslation } from "react-i18next";
import "./AboutOtilia.css";

import forground from "../assets/img/4fda928116fa0c28bc4493c723381570.jpeg";
import background from "../assets/img/647b141b0df4b0884cf98b6826d422ad.png";

const AboutOtilia = () => {
  const { t } = useTranslation();
  return (
    <section id="about" className="about-section py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 position-relative">
            <div className="image-wrapper">
              <img
                src={forground}
                alt="Background"
                className="img-fluid rounded background-img"
              />
              <img
                src={background}
                alt="Foreground"
                className="img-fluid rounded foreground-img"
              />
            </div>
          </div>
          <div className="col-md-6">
            {/* <h2>About</h2> */}
            <h3 className="display-6">{t("about.title")}</h3>
            <hr />
            <p>{t("about.description")}</p>
            <h4>{t("about.missionTitle")}</h4>
            <p>
              <strong>{t("about.missionDescription")}</strong>{" "}
              {t("about.missionDescription1")}
            </p>
            <h5>{t("about.values")}</h5>
            <p>
              <strong>{t("about.values_1_title")}</strong> {t("about.values_1")}
            </p>
            <p>
              <strong>{t("about.values_2_title")}</strong> {t("about.values_2")}
            </p>
            <p>
              <strong>{t("about.values_3_title")}</strong> {t("about.values_3")}
            </p>
            <p>
              <strong>{t("about.values_4_title")}</strong> {t("about.values_4")}
            </p>
            <p>
              <strong>{t("about.values_5_title")}</strong> {t("about.values_5")}
            </p>
            <p>
              <strong>{t("about.values_6_title")}</strong> {t("about.values_6")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutOtilia;
