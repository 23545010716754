// src/components/WhyChooseOtilia.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./WhyChooseOtilia.css";

import image from "../assets/img/9c28eee7264ed012d2615b8c24b5b922.jpeg";

const WhyChooseOtilia = () => {
  const { t } = useTranslation();

  return (
    <section id="whyUs" className="why-choose-section py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img
              src={image}
              alt="Mother and Baby"
              className="img-fluid rounded"
              height={400}
              width={600}
            />
          </div>
          <div className="col-md-6">
            <h3 className="display-6">{t("whyChooseOtilia.title")}</h3>
            <p>{t("whyChooseOtilia.description")}</p>
            <a
              href="https://app.otiliaai.com/#/register"
              className="btn btn-primary btn-lg"
            >
              {t("whyChooseOtilia.registerButton")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseOtilia;
