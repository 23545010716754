// src/components/VideoCallIntegration.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./VideoCallIntegration.css";

import image from "../assets/img/78e468cdd8e1a612dc83b6736efe96d3.jpeg";

const VideoCallIntegration = () => {
  const { t } = useTranslation();

  return (
    <section id="videoCalls" className="video-call-section py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h3 className="display-6">{t("videoCalls.title")}</h3>
            <p>{t("videoCalls.description")}</p>
            <p>{t("videoCalls.option_1")}</p>
            <p>{t("videoCalls.option_2")}</p>
            <p>{t("videoCalls.option_3")}</p>
            <p>{t("videoCalls.option_4")}</p>
            <p>{t("videoCalls.description1")}</p>
          </div>
          <div className="col-md-6">
            <img
              src={image}
              alt="Video Call"
              className="img-fluid rounded"
              height={400}
              width={600}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoCallIntegration;
