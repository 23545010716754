// src/components/WhatOtiliaOffers.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./WhatOtiliaOffers.css";
import OfferCard from "./OfferCard";

import features_1 from "../assets/img/features_1.png";
import features_2 from "../assets/img/features_2.png";
import features_3 from "../assets/img/features_3.png";

const WhatOtiliaOffers = () => {
  const { t } = useTranslation();

  return (
    <section id="features" className="offers-section py-5">
      <div className="container">
        <h2 className="text-center mb-4">{t("whatOtiliaOffers.title")}</h2>
        <p className="text-center mb-5">{t("whatOtiliaOffers.description")}</p>
        <div className="row mb-5">
          <OfferCard
            title={t("whatOtiliaOffers.chatTitle")}
            description={t("whatOtiliaOffers.chatDescription")}
            imageUrl={features_1}
            reverse={true}
          />
        </div>
        <div className="row mb-5">
          <OfferCard
            title={t("whatOtiliaOffers.programsTitle")}
            description={t("whatOtiliaOffers.programsDescription")}
            imageUrl={features_2}
            reverse={false}
          />
        </div>
        <div className="row">
          <OfferCard
            title={t("whatOtiliaOffers.specialistConnectionsTitle")}
            description={t("whatOtiliaOffers.specialistConnectionsDescription")}
            imageUrl={features_3}
            reverse={true}
          />
        </div>
      </div>
    </section>
  );
};

export default WhatOtiliaOffers;
