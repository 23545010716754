// src/components/Navbar.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./Navbar.css";

import logo from "../assets/img/logo.png";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src={logo}
            width={64}
            alt="Otilia Logo"
            className="d-inline-block align-top"
          />
          {/* <span className="logo-text">Otilia</span> */}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="/">
                {t("nav.home")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#features">
                {t("nav.features")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#benefits">
                {t("nav.benefits")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                {t("nav.about")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#videoCalls">
                {t("nav.videoCalls")}
              </a>
            </li>
          </ul>
          <div className="d-flex">
            {/* <a
              href="https://app.otiliaai.com/#/login"
              className="btn btn-link login-btn"
            >
              {t("nav.login")}
            </a> */}
            <a
              href="https://app.otiliaai.com/#/register"
              className="btn btn-primary join-btn"
            >
              {t("nav.register")}
            </a>
            {/* &nbsp;&nbsp;&nbsp;
            <select
              className="form-select"
              onChange={(e) => changeLanguage(e.target.value)}
              defaultValue={i18n.language}
            >
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="fr">Français</option>
              <option value="pt">Português</option>
            </select> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
