// src/components/MeetOurTeam.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./MeetOurTeam.css";

import leti from "../assets/img/leti.jpeg";
import hugo from "../assets/img/hugo.jpeg";
import liza from "../assets/img/liza.jpeg";
import clau from "../assets/img/clau.jpeg";

const MeetOurTeam = () => {
  const { t } = useTranslation();

  return (
    <section className="team-section py-5">
      <div className="container">
        <h3 className="text-center display-6">{t("team.title")}</h3>
        <p className="text-center">{t("team.description")}</p>
        <div className="row text-center">
          {t("team.members", { returnObjects: true }).map((member, index) => (
            <div key={index} className="col-md-3 mb-4">
              <div className="team-member">
                <img
                  src={
                    member.imageUrl === "leti"
                      ? leti
                      : member.imageUrl === "hugo"
                      ? hugo
                      : member.imageUrl === "liza"
                      ? liza
                      : clau
                  }
                  alt={member.name}
                  className="img-fluid rounded-circle mb-3"
                  width={150}
                  height={150}
                />
                <h5>{member.name}</h5>
                <p>{member.title}</p>
                <p>{member.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MeetOurTeam;
