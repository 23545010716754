// src/components/OfferCard.js
import React from "react";
import PropTypes from "prop-types";
import "./OfferCard.css";

const OfferCard = ({ title, description, imageUrl, reverse }) => {
  return (
    <div
      className={`row ${
        reverse ? "flex-row-reverse" : ""
      } align-items-center mb-5`}
    >
      <div className="col-md-6 mb-4 mb-md-0">
        <img src={imageUrl} alt={title} className="img-fluid rounded" />
      </div>
      <div className="col-md-6">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

OfferCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

OfferCard.defaultProps = {
  reverse: false,
};

export default OfferCard;
