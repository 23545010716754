// src/components/Footer.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer-section py-3">
      <div className="container text-center">
        <p>{t("footer.copyright")}</p>
      </div>
    </footer>
  );
};

export default Footer;
