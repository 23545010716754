// src/App.js
import React from "react";
import Navbar from "./components/Navbar";
import WelcomeSection from "./components/WelcomeSection";
import WhatOtiliaOffers from "./components/WhatOtiliaOffers";
import BenefitsOfUsingOtilia from "./components/BenefitsOfUsingOtilia";
import AboutOtilia from "./components/AboutOtilia";
import MeetOurTeam from "./components/MeetOurTeam";
import VideoCallIntegration from "./components/VideoCallIntegration";
import WhyChooseOtilia from "./components/WhyChooseOtilia";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div>
      <Navbar />
      <WelcomeSection />
      <WhatOtiliaOffers />
      <BenefitsOfUsingOtilia />
      <AboutOtilia />
      <MeetOurTeam />
      <VideoCallIntegration />
      <WhyChooseOtilia />
      <Footer />
    </div>
  );
};

export default App;
